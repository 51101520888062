import * as React from "react"
// import {Link, StaticQuery} from "gatsby"
import { Link } from 'gatsby'
import videoSmall from "../../assets/video/hero-video-small.mp4";
import videoLarge from "../../assets/video/hero-video-large.mp4";
import "./index.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="hero">
      <div className="hero-videos">
        <video autoPlay muted loop id="hero-video-small">
          <source src={videoSmall} type="video/mp4" />
        </video>
        <video autoPlay muted loop id="hero-video-large">
           <source src={videoLarge} type="video/mp4" />
        </video>
      </div>
      <h2>
        <span>
          Planting 100 trees a year for every validator we operate
        </span>
      </h2>
    </div>
    <div className="subhero">
      <h3>Stake with us, sustainably</h3>
      <span>Earn staking rewards and support global reforresting efforts.</span>
      <a
        style={{
          textDecoration: `none`
        }}
        href="#why-100-trees"
      >
        <div className="orange-button">Why 100 trees?</div>
      </a>
    </div>
    <div className="section networks" id="networks">
      <h2>Networks</h2>
      <ul className="networks-list">
        <Link to="/networks/solana">
          <li className="network-solana">
            <h4>Solana</h4>
          </li>
        </Link>
        <Link to="/networks/azero">
          <li className="network-azero">
            <h4>Aleph Zero</h4>
          </li>
        </Link>
        <Link to="/networks/bitscrunch">
          <li className="network-bitscrunch">
            <h4>bitsCrunch</h4>
          </li>
        </Link>
      </ul>
    </div>
    <div className="section why-100" id="why-100-trees">
      <h2>Why 100 trees?</h2>
      <div className="why-100-container">
        <p>
          Based on <a href="https://www.goclimate.com/blog/the-carbon-footprint-of-servers/">rough estimates</a>, a bare metal server using non green energy will have a whopping carbon footprint of about 916 kg CO2e per year, including production and use.
        </p>
        <p>
          We always try and contract providers that use green energy, but we're taking the pessimistic option in our calculations since you never know how the energy is actually sourced, especially when energy prices are nearing record highs.
        </p>
        <p>
          To cancel out the CO2 emissions generated by our servers, we decided the best way to go about it is to plant trees. It's a well established method of creating carbon sinks and adding to the natural world. On the lower estimates, in one year, a tree will absorb from 10kg of CO2 upwards. So to cancel out what one of our servers generates in a year we would need 91 trees, we round that number up and this is how we get 100 trees per server. Of course each tree will absorb much more CO2 during it's lifespan, but that's just a nice bonus.
        </p>
        <p>
          We happily pay <b>One Tree Planted NGO</b> to plant the trees for us.
        </p>
      </div>
    </div>
    <div className="section say-hi" id="say-hi">
      <h2>Say hi!</h2>
      <h3>Send us an email at contact@sustainablevalidato.rs or use the below form</h3>
      <div className="say-hi-container">
        <form target="_blank" action="https://formsubmit.co/contact@sustainablevalidato.rs" method="POST">
          <input type="hidden" name="_url" value="https://sustainablevalidato.rs" />
          <div className="form-group">
            <div className="form-row">
              <div className="col">
                <label htmlFor="contact-name">Full Name</label>
                <input type="text" id="contact-name" name="name" className="form-control" required autoComplete="off" />
              </div>
              <div className="col">
                <label htmlFor="contact-email">Email Address</label>
                <input type="email" id="contact-email" name="email" className="form-control" required autoComplete="off" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="contact-message">Your Message</label>
            <textarea id="contact-message" className="form-control" name="message" rows="10" required></textarea>
          </div>
          <button type="submit" className="orange-button">Submit Form</button>
        </form>
      </div>
    </div>
  </Layout>
)

export default IndexPage
